export const SELECTED_REQUESTS_STORAGE_TOKEN = 'SELECTED_REQUESTS'
export const SELECTED_ACTION_TYPE_STORAGE_TOKEN = 'SELECTED_ACTION'
export const REQUEST_COMMENTS = 'REQUEST_COMMENTS'
export const POSTED_REQUESTS_STORAGE_TOKEN = 'POSTED_REQUESTS'
export const INIT_INDICATOR = 'INIT_INDICATOR'

export function getStoredInitIndicator(): string {
  if (window !== undefined) {
    try {
      const storedInitIndicator = window.sessionStorage.getItem(INIT_INDICATOR) || ''
      return storedInitIndicator
    } catch (e) {
      return ''
    }
  }
  return ''
}

export function setStoredInitIndicator() {
  if (window !== undefined) {
    window.sessionStorage.setItem(INIT_INDICATOR, 'session_init_true')
  }
}

export function clearStoredInitIndicator() {
  if (window !== undefined) {
    window.sessionStorage.removeItem(INIT_INDICATOR)
  }
}

export function getStoredRequestIds(): string[] | [] {
  if (window !== undefined) {
    try {
      const storedRequestIds = window.sessionStorage.getItem(SELECTED_REQUESTS_STORAGE_TOKEN)
      return storedRequestIds ? <string[] | []>JSON.parse(storedRequestIds) : []
    } catch (e) {
      return []
    }
  }
  return []
}

export function getStoredPostedRequestIds(): string[] | [] {
  if (window !== undefined) {
    try {
      const storedRequestIds = window.sessionStorage.getItem(POSTED_REQUESTS_STORAGE_TOKEN)
      return storedRequestIds ? <string[] | []>JSON.parse(storedRequestIds) : []
    } catch (e) {
      return []
    }
  }
  return []
}

export function getStoredActionType() {
  if (window !== undefined) {
    const storedActionType = window.sessionStorage.getItem(SELECTED_ACTION_TYPE_STORAGE_TOKEN)
    return storedActionType || ''
  }
  return ''
}

export function getStoredComments() {
  if (window !== undefined) {
    const storedComments = window.sessionStorage.getItem(REQUEST_COMMENTS)
    return storedComments || ''
  }
  return ''
}

export function setStoredRequestIds(requestIdsToStore: any[] | []) {
  if (window !== undefined) {
    window.sessionStorage.setItem(SELECTED_REQUESTS_STORAGE_TOKEN, JSON.stringify(requestIdsToStore))
  }
}

export function setStoredPostedRequestIds(requestIdsToStore: any[] | []) {
  if (window !== undefined) {
    try {
      const postedRequestIds = getStoredPostedRequestIds()
      window.sessionStorage.setItem(
        POSTED_REQUESTS_STORAGE_TOKEN,
        JSON.stringify([...new Set([...postedRequestIds, ...requestIdsToStore])])
      )
    } catch (e) {
      window.sessionStorage.setItem(POSTED_REQUESTS_STORAGE_TOKEN, JSON.stringify(requestIdsToStore))
    }
  }
}

export function setStoredActionType(actionTypeToStore = '') {
  if (window !== undefined) {
    window.sessionStorage.setItem(SELECTED_ACTION_TYPE_STORAGE_TOKEN, actionTypeToStore)
  }
}

export function setStoredComments(commentsToStore = '') {
  if (window !== undefined) {
    window.sessionStorage.setItem(REQUEST_COMMENTS, commentsToStore)
  }
}

export const clearStoredTokens = () => {
  if (window !== undefined) {
    window.sessionStorage.removeItem(SELECTED_REQUESTS_STORAGE_TOKEN)
    window.sessionStorage.removeItem(SELECTED_ACTION_TYPE_STORAGE_TOKEN)
    window.sessionStorage.removeItem(POSTED_REQUESTS_STORAGE_TOKEN)
    window.sessionStorage.removeItem(REQUEST_COMMENTS)
  }
}

// export function getStoredAuthRequests(): any {
//   if (window !== undefined) {
//     try {
//       const storedSelectedRequests: string | null = window.sessionStorage.getItem(
//         SELECTED_REQUESTS_STORAGE_TOKEN
//       )
//       if (storedSelectedRequests && JSON.parse(storedSelectedRequests)) {
//         return JSON.parse(storedSelectedRequests) || {}
//       }
//       return {}
//     } catch (e) {
//       return {}
//     }
//   }
//   return {}
// }

// export function setStoredAuthRequestsBatch(requests: string[] | [], actionType = '', status = '') {
//   if (window !== undefined) {
//     const storedSelectedRequests = getStoredAuthRequests()
//     const requestsToStore: any = { ...storedSelectedRequests }
//     requests.forEach((req) => {
//       requestsToStore[req] = {
//         status,
//         actionType,
//         requestNumber: req,
//       }
//     })
//     window.sessionStorage.setItem(SELECTED_REQUESTS_STORAGE_TOKEN, JSON.stringify(requestsToStore))
//   }
// }

// export function getStoredRequest(requestNumber: string): { string: any } | null {
//   if (window !== undefined) {
//     // this 'any' trick is a hack to get around my frustration with typescript ATM
//     const storedSelectedRequests = getStoredAuthRequests()
//     if (!!requestNumber && !!storedSelectedRequests[requestNumber]) {
//       return storedSelectedRequests[requestNumber]
//     }
//   }
//   return null
// }

export const getPaymentColor = (purposeType: any, RequestTypes: any) => {
  const color = RequestTypes.map((item: any) => {
    if (item.PaymentType === 'Comp') {
      return 'black'
    } else if (purposeType === 'Employee - Buy' && item.PaymentType === 'CreditCard') {
      return 'green'
    } else {
      return 'red'
    }
  })

  return color[0]
}

export const hasCreditCardOrExternal = (request: any) =>
  request.RequestTypes.some(
    (item: any) => item.PaymentType === 'CreditCard' || item.PaymentType === 'External'
  )

export const getExportCSVHeaders = () => [
  { label: 'RequesTix ID', key: 'RequestNumber' },
  { label: 'Requested on behalf', key: 'RequestedFor' },
  { label: 'Title', key: 'RequestedForTitle' },
  { label: 'Recipient', key: 'Recipient' },
  { label: 'Cell', key: 'MobileNumber' },
  { label: 'Recipient email', key: 'RecipientEmail' },
  { label: 'Team', key: 'Team' },
  { label: 'Price point', key: 'SeatPreference' },
  { label: 'Total tix', key: 'TotalTickets' },
  { label: 'Section', key: 'SeatSection' },
  { label: 'Row', key: 'SeatRow' },
  { label: 'Seats', key: 'Seat' },
  { label: 'Archtics ID', key: 'ArchticId' },
  { label: 'Sent', key: 'SentRemarks' },
  { label: 'Purpose text', key: 'Purpose' },
]

export const csvData = (data: any) =>
  data?.map((req: any) => ({
    RequestNumber: req.RequestNumber,
    RequestedFor: req.RequestedFor,
    RequestedForTitle: req.RequestedForTitle,
    Recipient: req.Recipient,
    MobileNumber: req.MobileNumber,
    RecipientEmail: req.RecipientEmail,
    SeatPreference: req?.SeatPreference?.split(' - ')[0],
    TotalTickets: req.TotalTickets?.toString(),
    SeatSection: req.SeatSection || '',
    SeatRow: req.SeatRow || '',
    Seat: req.Seat?.toString(),
    ArchticId: req.ArchticId || '',
    SentRemarks: req.SentRemarks || '',
    Purpose: req.PurposeType || '',
  }))
