import { ApprovalQueueDetailItemType, RequestDetailItemType } from '../common-types'
import { endpoints } from '../config'
import { getApiClient, postApiClient } from './api-client'
import mockRequests from './mockdata/mockdata-requests.json'

export async function getMockRequests() {
  return mockRequests
}

export async function getAllRequests({
  employeeId = '',
  date = '',
  eventName = '',
  venueName = '',
  requestNumber = '',
  page = 1,
  pageSize = 15,
} = {}) {
  const params = {
    pageObject: JSON.stringify({
      skip: '0',
      take: pageSize,
      page,
      pageSize,
    }),
    date,
    eventName,
    venueName,
    requestNumber,
    employeeId,
    eventId: '',
    // eventStatus: '',
    page,
    pageSize,
  }

  return getApiClient({ endpoint: endpoints.getRequests, params })
}

export function getRequestDetail(
  { requestNumber } = { requestNumber: '' }
): Promise<RequestDetailItemType> {
  const params = {
    requestNumber: requestNumber,
  }
  return getApiClient({ endpoint: endpoints.getRequestDetail, params })
}

export function cancelTicketRequest({
  requestId,
  employeeId,
}: {
  requestId: string
  employeeId: string
}) {
  const params = {
    requestId: String(requestId),
    employeeId,
  }
  return postApiClient({ endpoint: endpoints.cancelTicketRequest, params })
}

export interface GetRequestLockedStatus {
  employeeId: string
  requestNumber: string | number
}
export async function getRequestLockedStatus(
  params: GetRequestLockedStatus
): Promise<ApprovalQueueDetailItemType> {
  return getApiClient({ endpoint: endpoints.getRequestLockedStatus, params }).catch(() => null)
}

export function sendEmailConfirmationRequest(requestNumber: string | number, ticketLocation: string) {
  const params = {
    requestNumber,
    ticketLocation,
  }
  return postApiClient({ endpoint: endpoints.sendTicketConfirmationEmail, params })
}
