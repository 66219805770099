import { AuthSignerQueueActionsType } from '../common-types'
import { endpoints } from '../config'
import { getApiClient, postApiClient, putApiClient } from './api-client'

export interface GetPoolCoordintaorData {
  employeeId: string
}

export interface SubmitPoolRequetsPrams {
  requestNumber: number
  employeeid: string | number
  id: string | number
  action: AuthSignerQueueActionsType | string
  state: string
  comments?: string
  ticketPoolId?: any
}

export async function getPoolCordinatorData({
  employeeId = '',
  date = '',
  search = '',
  venueName = '',
  eventId = '',
  requestNumber = '',
  purposeType = '',
  eventName = '',
  page = 1,
  pageSize = 10,
}: any) {
  const params = {
    page,
    pageSize,
    date,
    search,
    venueName,
    requestNumber,
    purposetype: purposeType,
    employeeId,
    eventId,
    eventName,
  }

  return getApiClient({ endpoint: endpoints.poolCordinatorRequets, params }).then((data) => {
    if (data) {
      return {
        ...data,
        Requests: data?.rows?.map((req: any) => ({ ...req, show: true })),
      }
    }
    return {
      Requests: [],
      TotalCount: 0,
    }
  })
}

export async function getTicketPools(): Promise<any> {
  return getApiClient({ endpoint: endpoints.getTicketPools }).catch(() => null)
}

export async function submitPoolProcessRequest(params: SubmitPoolRequetsPrams) {
  return postApiClient({ endpoint: endpoints.submitPoolProcessRequest, params })
}

export async function resendPaymentLink(params: any): Promise<any> {
  return postApiClient({ endpoint: endpoints.resendPaymentLink, params }).catch(() => null)
}

export async function requestRollback(params: any): Promise<any> {
  return putApiClient({ endpoint: endpoints.requestRollback, params }).catch(() => null)
}
